export const PREVIEW_AUTH_HEADER_NAME = 'X-Preview-Auth'

/**
 * The name of the HTTP header that we send the site key in
 */
export const SITE_KEY_HEADER_NAME = 'X-Site-Key'

/**
 * Algolia florist search radius in meters - 15 miles (1 miles == 1.60934km)
 */
export const FLORIST_GEO_SEARCH_RADIUS_METERS = 15 * 1609

export const cssForPCCSkeleton = { xs: '212px', sm: '212px', md: '86px', lg: '86px', xl: '86px' }

export const blogCategories = [
  '/blog/all',
  '/blog/life',
  '/blog/news',
  '/blog/style',
  '/blog/wedding',
  '/blog/occasions',
  '/blog/types-of-flowers',
]
